.errorContainer {
  padding: 20px;
  background: #f6645a;
  color: white;
  font-size: 15px;
}

.errorContainer i {
  color: #f6645a;
}

.successContainer {
  padding: 20px;
  background-color: #6bbd6f;
}

.success {
  color: white;
  font-size: 16px;
}

.successContainer i {
  display: inline;
  margin-left: 20px;
}

.inlineError {
  color: #f6645a;
}
