.covid {
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

.covid .titleContent {
  width: 100%;
  margin: 50px auto;
  text-align: center;
  padding: 30px;
}
@media (min-width: 375px) {
  .covid .titleContent {
    width: 70%;
    margin: 50px auto;
  }
}

.covid .titleContent .titleContent_title {
  margin-bottom: 30px;
  font-size: 18px;
}
@media (min-width: 375px) {
  .covid .titleContent .titleContent_title {
    font-size: 30px;
  }
}

.covid .titleContent .titleContent_text {
  font-size: 18px;
}
@media (min-width: 375px) {
  .covid .titleContent .titleContent_text {
    font-size: 20px;
  }
}

.gradual {
  position: relative;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 670px) {
  .gradual {
    padding: 50px 30px;
    justify-content: space-between;
    flex-direction: row;
  }
}

.gradual_img {
  flex: 40%;
}

.gradual_img img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.gradual_textContent {
  flex: 60%;
}

.gradual ul li {
  list-style: decimal;
  color: black;
}

@media (min-width: 375px) {
  .gradual ul li {
    font-size: 18px;
  }
}
.gradual ul li:not(:first-child) {
  margin-top: 20px;
}

.gradual_secondaryList {
  margin: 20px 35px;
}

.hr {
  width: 50%;
  height: 20%;
  border: 1px solid #000000;
}

.scale {
  display: flex;
  flex-direction: column;
}

@media (min-width: 675px) {
  .scale {
    flex-direction: row;
    padding: 30px 20px;
  }
}

.scaleContent {
  flex: 60%;
  padding: 10px;
}

.scaleContent h2 {
  margin-bottom: 20px;
}

@media (min-width: 675px) {
  .scaleContent p {
    font-size: 18px;
  }
}

.scale_list li {
  list-style: decimal;
  color: #000000;
}

.scaleImg {
  flex: 40%;
}

.scaleImg img {
  width: 80%;
  height: 80%;
  box-shadow: none;
}

.scale ul li:not(:first-child) {
  margin-top: 20px;
}

.schedule {
  position: relative;
  width: 70%;
  margin: 30px auto;
  padding: 30px 30px 50px 30px;
  text-align: center;
}

.schedule h2 {
  margin-bottom: 20px;
}

.schedule p {
  margin-bottom: 20px;
}

@media (min-width: 675px) {
  .schedule p {
    font-size: 18px;
    text-align: center;
  }
}

.faq {
  position: relative;

  width: 60%;
  margin: 0 auto;
}

.faq h1 {
  text-align: center;
  margin-bottom: 20px;
}

.faq .faqContent {
  padding: 20px;
  margin: 20px 5px;
}

.faq .faqContent .faqQuestion {
  font-weight: bold;
  margin-bottom: 10px;
}

.plan {
  width: 50%;
  margin: 0 auto;
}

.plan li {
  list-style: disc;
}
.plan li a {
  color: black;
  font-weight: bold;
}

.plan li a:hover {
  color: #256dfe;
}
.plan li:not(:first-child) {
  margin-top: 20px;
}
