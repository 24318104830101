.helm-featureDetail {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.helm-featureDetail img {
  border-radius: 0;
  box-shadow: none;
}
.helm-featureDetail i {
  font-size: 25px;
  color: #1fdd95;
}
@media (min-width: 670px) {
  .helm-featureDetail {
    margin-bottom: 0;
  }
}

.helm-featureDetail__icon {
  margin: 0 16px 20px 0;
  display: flex;
}
.helm-featureDetail__icon {
  margin: 0 16px 20px 0;
  display: flex;
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.helm-color-green {
  background-color: #1fdd95;
}
.helm-featureDetail__title,
.helm-featureDetail__body {
  margin-bottom: 8px;
}

.commonBodyTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;
}

.commonBodyTitle-slate1 {
  color: #32325d;
  font-weight: 700;
}

.commonBodyText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}
