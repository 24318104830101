.boxMenu {
  transform: translateX(10%);
  position: absolute;
  left: -50px;
  transition: all 0.7s ease-in-out;
  border: 1px solid #b8b7ad;
}

.show {
  display: none;
  transition: display 0.7 ease-in;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-group {
  outline: none;
  line-height: 30px;
  max-width: 250px;
  margin: auto;
}

.menu-group ul, .menu-group li {
  list-style-position: inside;
  margin-left: 0;
  padding-left: 0;
  text-align: center;
 }

.menu-group img {
  box-shadow: none;
  width: 100%;
  max-width: 200px;
  height: initial;
  display: block;
  margin: auto;
}
.menu-group h2 {
  font-size: 16px;
  font-weight: 425;
  color: #1FDD95;
  text-transform: uppercase;
  text-align: center;
}

.menu-group li a {
  font-size: 16px;
  color: #000;
  transition: color 0.3s ease-in-out;
}

.menu-group li a:visited {
  color: #87a2ff, 100%;
}
.menu-group li a:hover {
  color: #1FDD95;
}

.sbtn {
  display: flex;
  background: #256DFE;
  color: #fff;
  padding: 0.5rem 1.2rem;
  justify-content: center;
  border-radius: 100px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sbtn:hover {
  transform: translateY(-3px);
  background: #1FDD95;
}