.DeepNav {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: calc(100 - 1);
  padding: 12px 0;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

@media (min-width: 670px) {
  .DeepNav {
    top: 150px;
  }
}
.DeepNav img {
  border-radius: 0;
  box-shadow: none;
}

.DeepNav .container-lg {
  position: relative;
  display: -webkit-box;
  -moz-box-pack: justify;
  display: flex;
  justify-content: space-between;
}

.DeepNav a.deepHome {
  display: flex;
  -moz-box-align: center;
  align-items: center;
}

.DeepNav .deepHome span {
  margin-left: 12px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.colorize {
  background: rgb(37, 109, 254);
  text-align: center;
}
