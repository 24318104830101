.globalFooter {
  z-index: 1;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f9fc;
  position: relative;
}

.globalFooterNav {
  padding: 65px 0 55px;
  color: #8898aa;
  line-height: 30px;
  font-size: 15px;
  white-space: nowrap;
}

.globalFooterNav .container-lg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.globalFooterNav .metaNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.globalFooterNav .metaNav .space {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.globalFooterNav .metaNav .copyright {
  margin-right: 0;
  color: #aab7c4;
}

@media (min-width: 880px) {
  .globalFooterNav .container-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

@media (min-width: 880px) {
  .globalFooterNav .metaNav {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0 20px;
  }
}

.globalFooterNav .siteNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (min-width: 670px) {
  .globalFooterNav .siteNav {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (min-width: 880px) {
  .globalFooterNav .siteNav {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}

.globalFooterNav .siteNav .column {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin-bottom: 20px;
}

@media (min-width: 670px) {
  .globalFooterNav .siteNav .column {
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    margin-right: 40px;
  }
}

@media (min-width: 880px) {
  .globalFooterNav .siteNav .column {
    margin-right: 30px;
  }
}

@media (min-width: 1040px) {
  .globalFooterNav .siteNav .column {
    margin-right: 40px;
  }
}

.globalFooterNav .siteNav .splitColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-start;
}

hr {
  width: 100%;
  margin: 20px auto;
  border-bottom: 1px dotted rgba(207, 215, 223, 0.25);
}

.globalFooterNav a {
  text-decoration: none;
  margin: auto;
  color: inherit;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}
