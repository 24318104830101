.container-lg {
  margin: 100px auto;
}
.tour {
  margin: 200px auto;
  display: flex;
  justify-items: center;
  justify-content: center;
  width: 80%;
  flex-direction: column-reverse;
}

.btns {
  display: flex;
  width: 40%;
  height: 100px;
  justify-content: space-between;
}

.formTour {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px auto;
  width: 100%;
}

.hide {
  display: none;
}

.show {
  display: flex;
}
.formTour input {
  width: 100%;
  padding: 10px;
  margin: 20px auto;
  transition: width 0.5s ease;
}

.formTour label {
  font-size: 16px;
  font-weight: 400;
}

.errorMessage {
  padding: 15px;
  color: white;
  background: red;
}

@media (max-width: 880px) {
  .formTour input[type="text"] {
    width: 100%;
  }
}

.formTour input[type="submit"] {
  width: 40%;
  margin: 0 auto;
  text-align: center;
  background-color: #256dfe;
  color: white;
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
}

@media (max-width: 880px) {
  .tour {
    width: 100%;
  }
  .btns {
    width: 100%;
  }
}

@media (min-width: 881px) {
  .btns {
    width: 35%;
  }
  .formTour {
    width: 40%;
  }
  .formTour label {
    font-size: 20px;
  }
}
