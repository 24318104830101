.CommonUpperCaseTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-family: 'Open Sans', sans-serif;
}
@media (min-width: 670px) {
  .CommonUpperCaseTitle {
    font-size: 21px;
    line-height: 32px;
  }
}

.commonBodyText {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}

.globalFooterCard {
  z-index: 499;
  z-index: calc(500 - 1);
  position: relative;
  display: block;
  overflow: hidden;
  padding: 40px 40px 40px 120px;
  margin: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition-property: color, background-color, box-shadow, transform;
  transition-duration: 0.3s;
}

.globalFooterCard:hover {
  transform: translateY(-3px);
}
.globalFooterCard h2 {
  margin: 0 0 5px;
  white-space: normal;
}

.globalFooterCard .helm-logo {
  position: absolute;
  width: 130px;
  height: 130px;
  left: -35px;
  top: calc(50% - 65px);
}
.globalFooterCard:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #aab7c4;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
}
@media (min-width: 670px) {
  .globalFooterCard {
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;
  }
}

@media (min-width: 880px) {
  .globalFooterCard {
    padding-left: 120px;
  }
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.helm-color-green {
  background-color: #1fdd95;
}

.commonLink {
  color: #6772e5;
  font-weight: 500;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
  cursor: pointer;
}

.globalFooterCard img {
  border-radius: 0;
  box-shadow: none;
}
