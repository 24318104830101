.commonIntroText {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
}
@media (min-width: 670px) {
  .commonIntroText {
    font-size: 24px;
    line-height: 36px;
  }
}

.color-blue {
  color: #32325d;
}
