.intro-visual {
  --logoSize: 50px;
  position: sticky;
  top: 0px;
  height: 200px;
  padding-bottom: 100%;
  margin-bottom: -190px;
  transition: filter 0.4s ease-in-out;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 670px) {
  .intro-visual--measured {
    transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) scale(0.8) !important;
    transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) !important;
  }
}

.intro-visual-container {
  position: absolute;
  width: 30%;
  height: 30%;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  padding: 15rem;
}

.intro-visual .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 100%;
  will-change: transform, opacity;
  box-shadow: 0 2px 5px 0 rgba(26, 26, 67, 0.05),
    13px 20px 125px -25px rgba(50, 50, 93, 0.5),
    16px 20px 75px -37.5px rgba(0, 0, 0, 0.6);
}

.intro-visual .layer--stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: initial;
}

.intro-visual .logo-rotator {
  position: absolute;
  left: calc(50% - var(--logoSize) / 2);
  top: calc(50% - var(--logoSize) / 2);
  width: var(--logoSize);
  height: var(--logoSize);
  border-radius: 100%;
}
.intro-visual .layer--stripe svg {
  width: 100%;
  height: 100%;
}

.intro-visual .logo,
.intro-visual .logo-scaler {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  will-change: transform;
}

.intro-visual .logo {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  border-radius: 500px;
}

.HeaderVisualLogo {
  background-size: 310px 310px;
  background-image: url('../../assets//favicon.svg');
}

.HeaderVisualLogo-img0 {
  background-position: 0 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img1 {
  background-position: -52px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img2 {
  background-position: 0 -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img3 {
  background-position: -52px -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img4 {
  background-position: -104px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img5 {
  background-position: -104px -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img6 {
  background-position: 0 -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img7 {
  background-position: -52px -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img8 {
  background-position: -104px -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img9 {
  background-position: -156px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img10 {
  background-position: -156px -52px;
  width: 50px;
  height: 50px;
}

/*
.yellow {
  background: yellow;
  width: 30px;
  height: 30px;
  opacity: 1;
}
.green {
  background: green;
  width: 30px;
  height: 30px;
  opacity: 1;
}
.blue {
  background: blue;
  width: 30px;
  height: 30px;
  opacity: 1;
} */
