.dynamic {
  position: relative;
  padding: 50px 20px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 30px;
}

.dynamic img {
  border-radius: 0;
  box-shadow: none;
  width: 40%;
  height: 40%;
}
.dynamicBloc {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dynamicBloc h1 {
  margin-bottom: 30px;
}

.dynamicBloc_content {
  display: flex;
  height: 100px;
  width: 50%;
  margin-left: 100px;
  flex-direction: column;
  justify-content: space-between;
}

.dynamicBloc_text {
  font-size: 18px;
  margin-bottom: 30px;
}
