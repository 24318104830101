* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::before,
::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

section {
  display: block;
}

body {
  overflow-x: hidden !important;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

li {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

blockquote {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-end: 40px;
          margin-inline-end: 40px;
}
aside {
  display: block;
}

html {
  display: block;
  color: text;
}
html {
  --fontFamily: "sohne-var", "Helvetica Neue", "Arial", sans-serif;
  --fontWeightNormal: 300;
  --fontWeightSemibold: 425;
  --fontWeightBold: 500;
  --knockoutColor: #fff;
  --buttonColor: #0a2540;
  --ctaFont: 425 15px/1.6 "Open Sans", sans-serif;
  --footerRowGap: 24px;
  --bodyFont: 300 18px/ 1.555555556 "Open Sans", sans-serif;
  --codeFontFamily: "SourceCodePro";
  --gutterWidth: 16px;
  --scrollbarWidth: 0px;
  --windowWidth: calc(100vw - var(--scrollbarWidth));
  --layoutWidth: calc(var(--windowWidth) - var(--gutterWidth) * 2);
  --layoutWidthMax: 1080px;
  --viewportWidthSmall: 375;
  --viewportWidthMedium: 600;
  --viewportWidthLarge: 1112;
  --viewportScale: calc(var(--windowWidth) / var(--viewportWidthLarge));
  --paddingTop: 160px;
  --paddingBottom: 160px;
  --columnPaddingNone: 0;
  --columnPaddingNormal: 16px;
  --columnPaddingMedium: 16px;
  --columnPaddingLarge: 16px;
  --columnPaddingXLarge: 16px;
  --rowGapNormal: 8px;
  --rowGapMedium: 24px;
  --ctaSpacing: 16px;
  --hoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  --guideDashedColor: rgba(66, 71, 112, 0.09);
  --rowGapLarge: 32px;
  --guideSolidColor: rgba(66, 71, 112, 0.06);
  --rowGapXLarge: 64px;
  --rowGap: var(--rowGapNormal);
  --columnCountMax: 1;
  --columnWidth: calc(var(--layoutWidth) / var(--columnCountMax));
  --columnMaxWidth: calc(var(--layoutWidthMax) * 0.25);
  --paddingLeft: var(--columnPaddingNormal);
  --paddingRight: var(--columnPaddingMedium);
  --headerPaddingLeft: var(--paddingLeft);
  --headerPaddingRight: var(--paddingRight);
  --bodyPaddingLeft: var(--paddingLeft);
  --bodyPaddingRight: var(--paddingRight);
  --footerPaddingLeft: var(--paddingLeft);
  --footerPaddingRight: var(--paddingLeft);
  --footerRowGap: var(--rowGapMedium);
  --ctaSpacing: 16px;
  --titleLetterSpacing: 0.2px;
  scroll-margin-top: var(--fixedNavScrollMargin);
}

@media (min-width: 600px) {
  html {
    --columnPaddingMedium: 32px;
    --columnPaddingLarge: 64px;
    --columnPaddingXLarge: 64px;
    --columnCountMax: 2;
  }
}

@media (min-width: 900px) {
  html {
    --columnPaddingXLarge: 112px;
    --columnCountMax: 4;
  }
}

@media (min-width: 1112px) {
  html {
    --layoutWidth: var(--layoutWidthMax);
    --gutterWidth: calc((var(--windowWidth) - var(--layoutWidth)) / 2);
  }
}

body {
  --sourceCodeFont: 500 14px/1.714285714 var(--codeFontFamily);
  --ctaFont: var(--fontWeightSemibold) 15px/1.6 var(--fontFamily);
  --inputFont: var(--fontWeightNormal) 15px/1.6 var(--fontFamily);
  --cardShadowXSmall: 0 2px 5px -1px rgba(50, 50, 93, 0.25),
    0 1px 3px -1px rgba(0, 0, 0, 0.3);
  --cardShadowSmall: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  --cardShadowMedium: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  --cardShadowLarge: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3);
  --cardShadowLargeInset: inset 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    inset 0 18px 36px -18px rgba(0, 0, 0, 0.3);
  --cardShadowXLarge: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3);
  --cardShadowXSMallMargin: 2px;
  --cardShadowSmallMargin: 8px;
  --cardShadowMediumMargin: 16px;
  --cardShadowLargeMargin: 32px;
  --cardShadowXLargeMargin: 48px;
  --cardBorderRadius: 8px;
  --scrollbarOffset: 10px;
  --angleLight: -3deg;
  --angleNormal: -6deg;
  --angleStrong: -12deg;
  --angleNormalSin: 0.106;
  --angleStrongSin: 0.212;
  --captionFont: 500 18px / 1.5555556 "Open Sans", sans-serif;
  --fixedNavHeight: 60px;
  --fixedNavSpacing: 48px;
  --fixedNavScrollMargin: calc(var(--fixedNavHeight) + var(--fixedNavSpacing));
  --hoverTransition: 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  --focusBoxShadow: 0 0 0 2px #4d90fe, inset 0 0 0 2px hsla(0, 0%, 100%, 0.9);
  font-family: "sohne-var", "Helvetica Neue", "Arial", sans-serif;
  font-family: var(--fontFamily);
  font-weight: 300;
  font-weight: var(--fontWeightNormal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Section {
  --sectionAngleSin: var(--angleNormalSin);
  --sectionAngle: 0;
  --sectionPaddingNormalMax: 128;
  --sectionPaddingSmallMax: 110;
  --sectionPaddingMin: 72;
  --sectionPaddingMax: var(--sectionPaddingNormalMax);
  --sectionPaddingTopMax: var(--sectionPaddingMax);
  --sectionPaddingBottomMax: var(--sectionPaddingMax);
  --sectionMarginBottom: 0;
  --sectionAngleHeight: calc(var(--windowWidth) * var(--sectionAngleSin));
  --sectionAnglePaddingBaseMin: 100;
  --sectionAnglePaddingBaseMax: var(--sectionPaddingMax);
  --sectionAnglePaddingTopBaseMax: var(--sectionAnglePaddingBaseMax);
  --sectionAnglePaddingBottomBaseMax: var(--sectionAnglePaddingBaseMax);
  --sectionOverflow: hidden;
  --sectionTransformOrigin: 100% 0;
  --sectionBackgroundOverflow: visible;
  position: relative;
  z-index: 1;
  margin-bottom: var(--sectionMarginBottom);
  color: var(--textColor);
  scroll-margin-top: calc(
    var(--fixedNavHeight) + var(--fixedNavSpacing) - var(--sectionPaddingTop)
  );
}

.Section {
  --sectionAnglePaddingTopBase: calc(
    var(--sectionAnglePaddingBaseMin) * 1px +
      (var(--sectionAnglePaddingTopBaseMax) - var(--sectionAnglePaddingBaseMin)) *
      (var(--windowWidth) - 375px) / 737
  );
  --sectionAnglePaddingBottomBase: calc(
    var(--sectionAnglePaddingBaseMin) * 1px +
      (
        var(--sectionAnglePaddingBottomBaseMax) -
          var(--sectionAnglePaddingBaseMin)
      ) * (var(--windowWidth) - 375px) / 737
  );
  --sectionAnglePaddingTop: calc(
    var(--sectionAngleHeight) + var(--sectionAnglePaddingTopBase) -
      var(--sectionAngleSin) * var(--gutterWidth)
  );
  --sectionAnglePaddingBottom: calc(
    var(--sectionAngleHeight) + var(--sectionAnglePaddingBottomBase) -
      var(--sectionAngleSin) * var(--gutterWidth)
  );
  --sectionPaddingTop: calc(
    var(--sectionPaddingMin) * 1px +
      (var(--sectionPaddingTopMax) - var(--sectionPaddingMin)) *
      (var(--windowWidth) - 375px) / 737
  );
  --sectionPaddingBottom: calc(
    var(--sectionPaddingMin) * 1px +
      (var(--sectionPaddingBottomMax) - var(--sectionPaddingMin)) *
      (var(--windowWidth) - 375px) / 737
  );
}

@media (min-width: 1112px) {
  .Section {
    --sectionAnglePaddingTopBase: calc(
      var(--sectionAnglePaddingTopBaseMax) * 1px
    );
    --sectionAnglePaddingBottomBase: calc(
      var(--sectionAnglePaddingBottomBaseMax) * 1px
    );
    --sectionPaddingTop: calc(var(--sectionPaddingTopMax) * 1px);
    --sectionPaddingBottom: calc(var(--sectionPaddingBottomMax) * 1px);
  }
}

element.style {
  --rowLayoutGap: var(--rowLayoutGapNormal);
}
.animatedIcon {
  --svgSize: 96px;
  --iconSize: 40px;
  position: absolute;
  top: calc(-1 * (var(--svgSize) - var(--iconSize)) / 2);
  left: 5px;
  display: block;
  pointer-events: none;
  color: white;
}
.angleTop {
  --sectionPaddingTop: var(--sectionAnglePaddingTop);
  --sectionAngle: var(--angleNormal);
}
.industryAngle {
  --sectionAngle: var(--angleLight);
}
.angleBottom {
  --sectionTransformOrigin: 0 0;
  --sectionPaddingBottom: var(--sectionAnglePaddingBottom);
  --sectionMarginBottom: calc(var(--sectionAngleHeight) * -1);
}

.Gradient__canvas {
  position: relative;
  display: block;
  width: inherit;
  height: 100%;
  opacity: 0;
}

.Gradient__canvas.isLoaded {
  opacity: 1;
  transition: opacity 1.8s ease-in 50ms;
}
.gradient:after {
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
        var(--gradientColorZero) 40%,
        var(--gradientColorTwoTransparent) 60%
      ) -620px -180px no-repeat,
    radial-gradient(
        var(--gradientColorThree) 33%,
        var(--gradientColorThreeTransparent) 67%
      ) -120px -24px no-repeat,
    radial-gradient(
        var(--gradientColorTwo) 40%,
        var(--gradientColorTwoTransparent) 70%
      ) -470px 150px no-repeat,
    var(--gradientColorZero);
}

@media (min-width: 600px) {
  .gradient:after {
    background: radial-gradient(
          var(--gradientColorZero) 40%,
          var(--gradientColorTwoTransparent) 60%
        ) -420px -180px no-repeat,
      radial-gradient(
          var(--gradientColorThree) 23%,
          var(--gradientColorThreeTransparent) 70%
        )
        240px -24px no-repeat,
      radial-gradient(
          var(--gradientColorTwo) 30%,
          var(--gradientColorTwoTransparent) 70%
        ) -270px 220px no-repeat,
      var(--gradientColorZero);
  }
}

@media (min-width: 900px) {
  .gradient::after {
    background: radial-gradient(
          var(--gradientColorThree) 23%,
          var(--gradientColorThreeTransparent) 67% 100%
        )
        385px -24px,
      radial-gradient(
          var(--gradientColorOne) 0,
          var(--gradientColorOneTransparent) 60% 100%
        ) -940px 290px,
      radial-gradient(
          var(--gradientColorTwo) 10%,
          var(--gradientColorTwoTransparent) 60% 100%
        ) -120px 250px,
      radial-gradient(
          var(--gradientColorOne) 40%,
          var(--gradientColorOneTransparent) 57% 100%
        )
        495px -44px,
      radial-gradient(
          var(--gradientColorZero) 30%,
          var(--gradientColorZeroTransparent) 67% 100%
        )
        122px -120px,
      radial-gradient(
          var(--gradientColorZero) 10%,
          var(--gradientColorZeroTransparent) 60% 100%
        ) -420px 120px,
      radial-gradient(
          var(--gradientColorTwo) 15%,
          var(--gradientColorTwoTransparent) 50% 100%
        ) -620px 0,
      radial-gradient(
          var(--gradientColorTwo) 25%,
          var(--gradientColorTwoTransparent) 50% 100%
        )
        520px -250px,
      var(--gradientColorTwo);
    background-repeat: repeat-y;
  }
}

.gradient.isLoaded:after {
  transition: -webkit-transform 1s 1s;
  transition: transform 1s 1s;
  transition: transform 1s 1s, -webkit-transform 1s 1s;
  -webkit-transform: scaleY(0.995);
  transform: scaleY(0.995);
}

.gradient-canvas .isLoaded {
  opacity: 1;
  transition: opacity 1.8s ease-in 50ms;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-wide,
.container-xl {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.container,
.container-lg {
  max-width: 1040px;
}

.container-wide,
.container-xl {
  max-width: 1160px;
}

.main-carousel {
  height: 400px;
}

.carousel-cell {
  width: 100%; /* full width */
  height: 400px; /* height of carousel */
  margin-right: 10px;
  text-align: center;
  font-size: 2rem;
  display: flex;
}

/***footer**/
.globalFooter {
  background: #f6f9fc;
  position: relative;
}

.globalFooter.withCards {
  background: transparent;
  overflow: hidden;
  padding-top: 500px;
  margin-top: -480px;
  pointer-events: none;
}

.globalFooter.withCards::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 600px;
  height: 6000px;
  background: #f6f9fc;
  -webkit-transform: skew(0, -12deg);
  transform: skew(0, -12deg);
}

.globalFooter.withCards::before {
  top: 420px;
  background-color: #edf2f7;
}
.globalFooter.withCards > * {
  pointer-events: auto;
  position: relative;
}

.globalFooterCard {
  z-index: 499;
  z-index: calc(500 - 1);
  position: relative;
  display: block;
  overflow: hidden;
  padding: 40px 40px 40px 120px;
  margin: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition-property: color, background-color, box-shadow, -webkit-transform;
  transition-property: color, background-color, box-shadow, transform;
  transition-property: color, background-color, box-shadow, transform, -webkit-transform;
  transition-duration: 0.3s;
}

.globalFooterCard:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.globalFooterCard h2 {
  margin: 0 0 5px;
  white-space: normal;
}

.globalFooterCard .helm-logo {
  position: absolute;
  width: 130px;
  height: 130px;
  left: -35px;
  top: calc(50% - 65px);
}
.globalFooterCard:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #aab7c4;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
}
@media (min-width: 670px) {
  .globalFooterCard {
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;
  }
}

@media (min-width: 880px) {
  .globalFooterCard {
    padding-left: 120px;
  }
}

.globalFooterCards .container-xl {
  display: flex;
  flex-wrap: wrap;
  margin: -15px auto;
  padding: 0 5px;
}
.helm-sectionCTA {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.helm-footerCta_container {
  display: flex;
  flex-direction: column;
}
.helm-footerCta__buttons,
.helm-footerCta__copy {
  flex: 1 0 50%;
}

.helm-footerCta__copy {
  margin: 24px 0;
}

@media (min-width: 670px) {
  .helm-footerCta_container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1160px) {
  .helm-footerCta__copy {
    flex-basis: 70%;
  }
}

.copy_bleue {
  color: #256dfe;
}
.color-cyan {
  color: #765de2;
}

.Helm-FooterCTA__title {
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #765de2;
  margin: 0.75em 0;
}
.Helm-FooterCTA__body,
.Helm-FooterCTA__title {
  font-size: 30px;
}

.subtitle {
  font-weight: 300;
  font-size: 20px;
  color: #6772e5;
  display: block;
}

@media (min-width: 1160px) {
  .helm-footerCta__buttons {
    flex-basis: 30%;
  }
}
@media (min-width: 670px) {
  .helm-footerCta__buttons {
    justify-content: flex-end;
  }
}

.adama {
  position: relative;
  padding: 160px 0;
}

@media (min-width: 670px) {
  .adama {
    padding-bottom: 220px;
  }
}

.commonLink {
  color: #6772e5;
  font-weight: 500;
  transition: color 0.1s ease;
  cursor: pointer;
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text-color-purple {
  color: #6772e5;
}

.text-color-bleue {
  color: #32325d;
}

.text-color-green {
  color: #1fdd95;
}

.helm-color {
  background-color: #c4f0ff;
}

.helm-color-green {
  background-color: #1fdd95;
}

.fine-print {
  margin-top: 32px;
}

@media (min-width: 670px) {
  .fine-print {
    margin-top: 0;
  }
}

@media (min-width: 880px) {
  .fine-print {
    width: 50%;
  }
}

.content {
  flex: 1 0 50%;
}

.title {
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #32325d;
  margin: 0.75em 0;
}

.common-ButtonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
@media (min-width: 670px) {
  .common-ButtonGroup {
    justify-content: flex-end;
  }
}

@media (min-width: 670px) {
  .globalFooter .container-lg {
    display: flex;
    align-items: center;
  }
}

.colorize {
  color: #0a2540;
}

.u-highlight {
  color: #256dfe;
}

em {
  color: #1fdd95;
  font-weight: 300;
  font-style: normal;
  padding-left: 20px;
}
.mtop {
  margin-top: 50px;
}

.commonIntroText {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
}
@media (min-width: 670px) {
  .commonIntroText {
    font-size: 24px;
    line-height: 36px;
  }
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.commonButton {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #6772e5;
  text-decoration: none;
  transition: all 0.3s ease;
}
.commonButton-default {
  color: #fff;
  background: #42d9d6;
}

.helm-color-cyan {
  background-color: #42d9d6;
}

.helm-cyan {
  color: #42d9d6;
}

.helm-color-green {
  color: #1fdd95;
}
.commonTitle {
  color: #0a2540;
  letter-spacing: -0.01em;
  font-size: 45px;
  font-weight: 300;
  line-height: 56px;
}
@media (min-width: 670px) {
  .commonTitle {
    font-size: 53px;
    line-height: 68px;
  }
}

.small-columns,
.medium-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 880px) {
  .medium-columns {
    flex-direction: row;
    justify-content: space-between;
  }
}

.small-column,
.small-row {
  display: flex;
  margin: -20px;
}

.small-row {
  display: flex;
  flex-direction: column;
}
@media (min-width: 670px) {
  .small-column {
    display: flex;
    flex-direction: row;
  }
}

.common-UppercaseTittle {
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

@media (min-width: 670px) {
  .common-UppercaseTittle {
    font-size: 21px;
    line-height: 32px;
  }
}
.commonBodyTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;
  text-decoration: none;
}
.commonBodyText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}

.commonLink {
  color: #6772e5;
  font-weight: 500;
  transition: color 0.1s ease;
  cursor: pointer;
}

.commonSectionTitle {
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  color: #32325d;
}
@media (min-width: 670px) {
  .commonSectionTitle {
    font-size: 42px;
    line-height: 52px;
  }
}

.globalFooterCards .container-lg,
.globalFooterCards .container-xl {
  display: flex;
  flex-wrap: wrap;
  margin: -15px auto;
  padding: 0 5px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

@media (min-width: 670px) {
  .globalFooterCards {
    margin-top: 50px;
  }
}
a.globalFooterCard {
  flex: 1 1 100%;
  margin: 15px;
  z-index: 499;
  z-index: calc(500 - 1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 40px 40px 40px 120px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition-property: color, background-color, box-shadow, -webkit-transform;
  transition-property: color, background-color, box-shadow, transform;
  transition-property: color, background-color, box-shadow, transform, -webkit-transform;
  transition-property: color, background-color, box-shadow, transform,
    -webkit-transform;
  transition-duration: 0.15s;
}

@media (min-width: 880px) {
  a.globalFooterCard,
  div.globalFooterCard {
    padding-left: 120px;
  }
}

@media (min-width: 670px) {
  a.globalFooterCard,
  div.globalFooterCard {
    flex-basis: 1%;
    padding-left: 100px;
  }
}

a.globalFooterCard p,
div.globalFooterCard p {
  margin: 5px 0 0;
}
a.globalFooterCard:after,
div.globalFooterCard:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #aab7c4;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
}

.globalFooterCard__icon {
  height: auto !important;
}

.globalFooterCard:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.common-UppercaseTittle.common-Link--arrow:after {
  content: "\279C";
}
.common-Link--arrow:after {
  font: normal 16px StripeIcons;
  content: "\2192";
  padding-left: 5px;
}

/****pricing***/
section.pricing {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 885px) {
  section.pricing {
    padding-top: 120px;
    padding-bottom: 120px;
    margin-bottom: 10px;
  }
}

@media (max-width: 880px) {
  section.pricing {
    margin-top: -150px;
    border: 1px solid red;
    margin-bottom: 0px;
  }
}
section.pricing .commonSectionTitle {
  position: relative;
  margin: 0 auto 30spx;
  text-align: center;
}
@media (min-width: 670px) {
  section.pricing .commonSectionTitle {
    margin-bottom: 60px;
  }
}

section.pricing .tiers {
  display: grid;
  grid: auto/1fr;
  grid-gap: 30px;
}
@media (min-width: 670px) {
  section.pricing .tiers {
    grid: auto/48px 1fr minmax(auto, 220px);
    grid-gap: 50px;
  }
}

@media (min-width: 670px) {
  section.pricing .description {
    border-right: 1px solid #e6ebf1;
    margin-right: -20px;
    padding-right: 40px;
  }
}

section.pricing .description .commonBodyText {
  margin: -2px 0 4px;
}

section.pricing .price {
  margin-top: -8px;
}
section.pricing .price:nth-child(3) {
  padding-bottom: 30px;
  border-bottom: 1px solid #e6ebf1;
}

@media (min-width: 670px) {
  section.pricing .price:nth-child(3) {
    padding-bottom: 0;
    border-bottom-style: none;
  }
}

section.pricing .price .commonIntroText {
  margin-bottom: 2px;
  font-weight: 500;
  color: #b76ac4;
}

@media (min-width: 670px) {
  .commonIntroText {
    font-size: 24px;
    line-height: 36px;
  }
}

section.pricing .pricing-note {
  font-size: 14px;
  line-height: 22px;
  color: #525f7f;
}

.pricing i {
  color: #f5c15c;
  margin-right: 10px;
}

.pricing li {
  display: flex;
  flex-direction: row;
}

/*****for teams******/

/******teams****/
.for-teams {
  position: relative;
}

section.for-teams > .background {
  position: absolute;
  width: 100%;
  top: -60px;
  bottom: 0;
  background-color: #f6f9fc;
  overflow: hidden;
}

@media (min-width: 880px) {
  section.for-teams > .background {
    top: -120px;
  }
}
section.for-teams > .background:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  background-color: #fff;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.Commoncards {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

@media (min-width: 880px) {
  .Commoncards {
    padding: 50px;
  }
}

.Commoncards {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  z-index: 1;
}

section.for-teams .Commoncards {
  padding: 0;
  background-color: #f6f9fc;
}

section.for-teams header {
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

section.for-teams header .copy {
  flex: 1 1 auto;
  margin-bottom: 20px;
}

section.for-teams .Commoncards > * {
  padding: 30px;
}

@media (min-width: 670px) {
  section.for-teams header .copy {
    margin: -2px 40px 0 20px;
  }
}
section .for-teams header .commonIntroText {
  margin-top: 6px;
}

section.for-teams header a {
  flex: 0 0 auto;
  margin-top: 6px;
}

@media (min-width: 670px) {
  section.for-teams .Commoncards > :first-child {
    padding: 50px 50px 40px;
  }
}

@media (min-width: 880px) {
  section.for-teams .medium-columns {
    align-items: center;
  }
}

@media (min-width: 1040px) {
  section.for-teams .medium-columns {
    align-items: flex-start;
  }
}

@media (min-width: 670px) {
  section.for-teams .Commoncards > :last-child {
    padding: 40px 50px 50px;
  }
}

section.for-teams .features {
  display: flex;
  flex-direction: column;

  width: 100%;
}
section.for-teams .features li {
  padding-left: 0px;
}

section.for-teams li i {
  color: #765de2;
}

@media (min-width: 670px) {
  section.for-teams li {
    padding-left: 68px;
    background-position: 13px 4px;
  }
}

section.for-teams li:not(:last-child) {
  margin-bottom: 20px;
}

.screenshot {
  --transform: rotate3d(1, 0, 0, 0deg);
  --shadow: 0px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
    0px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
  --lighting-angle: 0deg;
  -webkit-perspective: 1500px;
  perspective: 1500px;
}

section.for-teams .screenshot {
  --transform: rotate3d(0.5, -0.866, 0, 19deg);
  --shadow: 34.3px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
    20.6px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
  --lighting-angle: 300deg;
  margin: 30px -40px 0 -50px;
}

@media (min-width: 670px) {
  section.for-teams .screenshot {
    margin: 30px 0 0 -20px;
  }
}

@media (min-width: 880px) {
  section.for-teams .screenshot {
    margin: -30px -70px 0 100px;
  }
}

section.for-teams .screenshot .image-container {
  border-radius: 8px;
  -webkit-transform: var(--transform);
  transform: var(--transform);
  box-shadow: var(--shadow);
}

.image-container img {
  border-radius: 8px;
  vertical-align: top;
  height: 500px;
  width: 100%;
}

section.for-teams .screenshot .image-container {
  transition-property: box-shadow, -webkit-transform;
  transition-property: transform, box-shadow;
  transition-property: transform, box-shadow, -webkit-transform;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.screenshot .image-container :after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    var(--lighting-angle),
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.35)
  );
  border-radius: 8px;
}

.Helm-UserCard__content {
  position: relative;
  z-index: 10;
}

.common-Card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

@media (min-width: 880px) {
  .common-Card {
    padding: 50px;
  }
}

.Helm-UserCard__card {
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1;
  will-change: transform;
}

.Helm-UserCard__imageContainer {
  position: relative;
  height: 200px;
  background: #525f7f;
  overflow: hidden;
}

@media (min-width: 670px) {
  .Helm-UserCard__imageContainer {
    height: 100%;
    position: absolute;
    z-index: 2;
    left: -500px;
    top: 0;
    right: calc(50% + 70px);
    -webkit-transform: skewX(-12deg);
            transform: skewX(-12deg);
  }
}

@media (min-width: 880px) {
  .Helm-UserCard__imageContainer {
    right: calc(50% + 100px);
  }
}

/***prcing***/
section.pricing .section-title {
  text-align: center;
}

section.pricing .business-section > .icon,
section.pricing .pricing-section > .icon {
  flex-basis: 48px;
  flex-shrink: 0;
  max-width: 48px;
  margin-bottom: 16px;
}
section.pricing .business-section,
section.pricing .pricing-section {
  margin-top: 64px;
}

@media (min-width: 670px) {
  section.pricing .business-section > .feature-list.core,
  section.pricing .pricing-section > .feature-list.core {
    padding-right: 64px;
    flex-basis: 70%;
  }
}
@media (min-width: 670px) {
  section.pricing .business-section,
  section.pricing .pricing-section {
    display: flex;
  }
}
@media (min-width: 670px) {
  section.pricing .business-section > .feature-list,
  section.pricing .pricing-section > .feature-list {
    padding: 0 64px;
    padding-right: 220px;
  }
}

section.pricing .business-section > .feature-list .feature-title,
section.pricing .pricing-section > .feature-list .feature-title {
  color: #32325d;
  margin-bottom: 0;
}
section.pricing h3 {
  color: #aab7c4;
  margin-bottom: 15px;
}
section.pricing .core ul {
  margin-top: 20px;
}

section.pricing .business-section > .feature-list .feature-lead,
section.pricing .pricing-section > .feature-list .feature-lead {
  color: #6b7c93;
  margin-top: 24px;
}
section.pricing .business-section > .feature-list ul,
section.pricing .pricing-section > .feature-list ul {
  margin-left: 0;
  margin-top: 0;
  padding-left: 20px;
}

section.pricing .business-section > .feature-list.core ul,
section.pricing .pricing-section > .feature-list.core ul {
  list-style: none;
  padding-left: 0;
}

@media (min-width: 670px) {
  section.pricing .core ul {
    margin-top: 0;
    margin-left: 30px;
  }
}
section.pricing .business-section > .feature-list ul li,
section.pricing .pricing-section > .feature-list ul li {
  color: #aab7c4;
  padding: 8px 0;
}

section.pricing .business-section > .feature-list ul li span,
section.pricing .pricing-section > .feature-list ul li span {
  color: #525f7f;
}

section.pricing .business-section > .feature-list.core ul li:before,
section.pricing .pricing-section > .feature-list.core ul li:before {
  content: "";
  display: inline-block;
  background-image: url("/right.svg");
  background-size: 100%;
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 4px;
  flex-shrink: 0;
}

section.pricing .business-section > .price,
section.pricing .pricing-section > .price {
  border-bottom: 1px solid #e6ebf1;
  flex-shrink: 0;
  padding: 16px 0 30px;
}
@media (min-width: 670px) {
  section.pricing .business-section > .price,
  section.pricing .pricing-section > .price {
    border-left: 1px solid #e6ebf1;
    border-bottom: 0;
    padding: 0 0 0 32px;
  }
}

section.pricing .business-section,
section.pricing .pricing-section {
  margin-top: 64px;
}

@media (min-width: 670px) {
  section.pricing .business-section,
  section.pricing .pricing-section {
    display: flex;
  }
}

section.pricing ul {
  list-style-type: disc;
  padding-left: 30px;
}

section.pricing .business-section > .feature-list ul li,
section.pricing .pricing-section > .feature-list ul li {
  color: #aab7c4;
  padding: 8px 0;
}

.business-section li {
  list-style: disc;
}

/*****kim**/

.kimIntro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 20px 0;
  text-align: center;
}

@media (min-width: 670px) {
  .kimIntro {
    padding: 80px 20px 0;
    margin-bottom: 80px;
  }
}

@media (min-width: 1285px) {
  .kimIntro {
    margin-top: 100px;
  }
}

.helm-large {
  height: 72px;
  width: 72px;
}
.helm-circle {
  border-radius: 50%;
}

.helm-color {
  background-color: #c4f0ff;
}

.kimIntro_title {
  display: flex;
  align-items: center;
  margin: 16px auto;
}

@media (min-width: 670px) {
  .kimIntro_title {
    margin-bottom: 20px;
  }
}

.kimIntro_text {
  max-width: 900px;
}

.commmonIntroText {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
}

@media (min-width: 670px) {
  .commmonIntroText {
    font-size: 24px;
    line-height: 36px;
  }
}

/*****wizard*****/

.helm-layoutColumns {
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 670px) {
  .helm-layoutColumns {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
}
.helm-layoutColumns_column {
  position: relative;
  width: 100%;
}
.wizard {
  background: #f6f9fc;
  padding: 0;
}
.wizardDemon {
  position: relative;
}

.wizard .helm-layoutColumns {
  margin: 20px auto;
  align-items: center;
}

.common-UppercaseTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}
@media (min-width: 670px) {
  .helm-Layoutcolumn--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 670px) {
  .wizard .helm-layoutColumns {
    margin-top: 80px;
  }
}

@media (min-width: 670px) {
  .common-UppercaseTitle {
    font-size: 21px;
    line-height: 32px;
  }
}

.wizardDemo {
  position: relative;
  background: #e6ebf1;
  padding: 30px;
}

@media (min-width: 670px) {
  .wizardDemo {
    -webkit-transform: perspective(1000px) scale(0.8) rotateX(2deg) rotateY(-13deg)
      translate3d(20px, 0, 100px);
            transform: perspective(1000px) scale(0.8) rotateX(2deg) rotateY(-13deg)
      translate3d(20px, 0, 100px);
  }
}

.wizardDemo,
.wizardDemo img {
  border-radius: 8px;
}

.wizardDemo__content {
  border-radius: 6px;
  overflow: hidden;
}

.wizardDemo__content img {
  min-width: 100%;
  border-radius: 0;
  box-shadow: none;
  min-height: 200px;
  object-fit: cover;
}

.wizardDemo-header {
  border-bottom: 1px solid #cfd7df;
  background: #fff;
  padding: 10px 20px;
}

.wizardDemo__body {
  background: #f6f9fc;
  padding: 20px;
}

.wizard-demo-label {
  margin-bottom: 8px;
  font-weight: 500;
}
.row {
  display: flex;
  margin-bottom: 20px;
}

.wizardDemo-footer {
  border-top: 1px solid #e6ebf1;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.wizardClock {
  margin-top: 80px;
}

@media (min-width: 670px) {
  .wizardClock {
    margin-top: 0;
    padding-bottom: 80px;
  }
}

.wizardcode {
  position: relative;
  transition: 0.75s ease-in-out;
  display: flex;
  width: 100%;
  height: 200px;
  padding-top: 100px;
  overflow: hidden;
}

.wizardCode__wrapper {
  width: 100%;
  border-radius: 8px;
  background-color: #32325d;
  color: #fff;
  font: 12px sans-serif;
  line-height: 1.8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 0;
}

.wizardCard {
  position: relative;
  margin-top: 80px;
}

@media (min-width: 670px) {
  .wizardCard {
    margin-top: 0;
  }
}

.santa__sled {
  margin-bottom: 30px;
}
@media (min-width: 670px) {
  .Santa__sled {
    display: flex;
  }
}

.santa__icon {
  width: 80px;
}

.santa__details {
  width: 100%;
}

@media (min-width: 670px) {
  .santa__details {
    width: calc(100% - 90px);
  }
}

.santa__title {
  margin: 9px 0 16px;
  color: #32325d;
}
.common-UppercaseTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

@media (min-width: 670px) {
  .common-UppercaseTitle {
    font-size: 21px;
    line-height: 32px;
  }
}

.santa__row {
  margin-bottom: 40px;
}
@media (min-width: 670px) {
  .santa__row {
    display: flex;
    align-items: baseline;
  }
}

@media (min-width: 670px) {
  .santa__copy {
    flex-basis: 80%;
    padding-right: 24px;
  }
}

.common-BodyTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;
}
.common-BodyText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}

.santa__row ul {
  margin-top: 10px;
}

@media (min-width: 670px) {
  .santa__row ul {
    display: flex;
    flex-wrap: wrap;
  }
}

.santa__row li {
  position: relative;
  padding: 4px 20px 4px 32px;
  font-size: 15px;
  list-style: disc;
}
@media (min-width: 670px) {
  .santa__row li {
    flex-basis: 50%;
    flex-shrink: 0;
  }
}

.santa__pricing {
  margin-top: 16px;
  border-left: 1px solid #e6ebf1;
  padding: 4px;
}

@media (min-width: 670px) {
  .santa__pricing {
    margin-top: 0;
    flex-basis: 30%;
  }
}

.santa_price {
  color: #24b47e;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.santa__priceLabel {
  margin-top: 4px;
  font-size: 15px;
  line-height: 22px;
}

.santa__pricing p:not(:last-of-type) {
  margin-bottom: 12px;
}

.small-columns,
.medium-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 880px) {
  .medium-columns {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 670px) {
  .small-columns {
    flex-direction: row;
    justify-content: space-between;
  }
}

/******quotes****/
.quotes {
  position: relative;
  z-index: 50;
}

.stripe-Container {
  pointer-events: none;
}

.quotes .stripes-Container > div:first-child {
  top: -245px;
  top: calc(245px * -1);
  background: linear-gradient(90deg, #d7dee8, rgba(215, 222, 232, 0) 30%);
}

.quotes .stripes-Container > div:nth-child(2) {
  bottom: -245px;
  bottom: calc(245px * -1);
  background: linear-gradient(90deg, rgba(207, 217, 228, 0) 70%, #cfd9e4);
}

.quotes .quotes-row {
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  margin: 0;
}

.quotes .cell {
  background: #32325d;
  display: flex;
  align-items: center;
  margin-top: -1px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 880px) {
  .quotes .cell {
    flex: 1 0 50%;
  }
}

.quotes .bottom-left,
.quotes .top-left {
  justify-content: flex-end;
}

.quotes .top-left {
  padding-top: 60px;
  background: #222d49;
}
@media (min-width: 880px) {
  .quotes .top-left {
    padding-top: 0;
    background: #222d49 linear-gradient(192deg, #222d49, #32385c);
  }
}

.quotes .inner {
  -webkit-transform: skewY(12deg);
  transform: skewY(12deg);
  text-align: center;
  padding: 80px 20px;
}

@media (max-width: 880px) {
  .quotes .inner {
    text-align: center;
    padding: 80px 0;
  }
  .quotes .inner svg {
    width: 50%;
  }
}

@media (max-width: 1124px) and (min-width: 880px) {
  .quotes .inner {
    padding: 50px 0;
    text-align: center;
    max-width: 580px;
    max-width: calc(1160px / 2);
  }
  .quotes .inner svg {
    width: 40%;
  }

  .special {
    padding: 17px 0;
  }
}

@media (min-width: 1040px) {
  .quotes .inner {
    padding: 140px 0;
  }
}

.quotes .inner .icon {
  display: block;
  transition: opacity 0.2s;
  margin: 35px auto;
}

.quotes .inner .icon:hover {
  opacity: 0.5;
}

.quotes .inner .quote-body {
  color: #fff;
  font: italic 20px/32px Camphor;
}

.quotes .inner .attribution {
  margin-top: 25px;
  color: #a78ce9;
}

.quotes .top-right {
  background: #2f3555;
}

@media (min-width: 880px) {
  .quotes .top-right {
    background: #313554 linear-gradient(192deg, #313554, #3b3c60);
  }
}

.quotes .bottom-left {
  background: #3e4065;
}

@media (min-width: 880px) {
  .quotes .bottom-left {
    background: #3b3d61 linear-gradient(192deg, #3b3d61, #4d4a78);
  }
}

.commonUpperText {
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.stripe-Container > div {
  position: absolute;
  left: 0;
  right: 0;
  height: 245px;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.quotes .stripe-Container > div:first-child {
  top: -240px;
  top: calc(245px * -1);
  background: linear-gradient(90deg, #d7dee8, rgba(215, 222, 232, 0) 30%);
}

/******pricing****/
.eulerity-price {
  background: #eef2f7;
  margin-top: -500px;
  padding-top: 590px;
}

@media (min-width: 880px) {
  .eulerity-price {
    padding-top: 640px;
  }
}

.eulerity-price ::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -600px;
  bottom: 190px;
  background: #e6ebf1;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.eulerity-price .section-header {
  position: relative;
  padding-top: 40px;
  z-index: 30;
}
.narrow-container {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}
.commoncard {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

@media (min-width: 880px) {
  .commoncard {
    padding: 50px;
  }
}

.eulerity-price .pricing-box {
  padding: 0;
  margin-top: 90px;
  z-index: 25;
  height: 500px;
  width: 100%;
  z-index: 10;
}

.badge {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
  background: #6b7c93;
  color: #fff;
  padding: 3px 15px;
  border-radius: 50px;
}

.totals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f6f9fc;
  padding: 30px 0;
}

@media (min-width: 670px) {
  .totals {
    flex-direction: row;
    padding: 60px 0 50px;
  }
}

.slider {
  height: 6px;
  margin: -3px 30px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y pinch-zoom;
}

@media (min-width: 670px) {
  .slider {
    margin: -3px 60px;
  }
}

.knob {
  position: absolute;
  width: 44px;
  height: 44px;
  margin: -19px 0 0 -22px;
  z-index: 20;
  background: #424770
    url(
      data:image/svg + xml;utf8,
      <svgxmlns="http://www.w3.org/2000/svg"width="26"height="18"stroke="white"stroke-width="1.5px"stroke-linecap="round"stroke-linejoin="round"fill="none"><pathd="M8.811,3.153 L3.014,8.950 L8.811,14.748 "/><pathd="M17.207,14.792 L23.004,8.995 L17.207,3.198 "/></svg>
    )
    50% no-repeat;
  border-radius: 50%;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  transition-property: background-color, box-shadow;
  transition-duration: 0.2s;
  cursor: ew-resize;
}

.rail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  display: flex;
}

.labels {
  flex-direction: column;
  padding-top: 50px;
}

.labels li {
  display: flex;
}

@media (min-width: 670px) {
  .labels {
    flex-direction: row;
    padding: 60px 60px 0;
  }
}

.infra-fee-row {
  margin-top: 10px;
  height: 60px;
  position: relative;
  text-align: center;
}
@media (min-width: 670px) {
  .infra-fee-row {
    height: 80px;
    margin: 10px 60px 0;
  }
}
.infra-fee {
  font-size: 15px;
  font-weight: 500;
  color: #32325d;
  text-align: center;
  opacity: 0;
  transition-property: left, opacity;
  transition-duration: 0.2s;
}

@media (min-width: 670px) {
  .infra-fee {
    width: 400px;
    margin-left: -200px;
    position: absolute;
  }
}

.copyBody {
  position: relative;
  padding: 0 112px 0 16px;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  color: #0a2540;
  letter-spacing: 0.2px;
}

.contactTitle {
  position: relative;
  padding-top: 50px;
  max-width: 680px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  transition: opacity 0.3s ease-out;
}

@media (min-width: 880px) {
  .contactTitle {
    padding-top: 80px;
  }
}

@media (min-width: 670px) {
  .contactTitle {
    padding-top: 50px;
  }
}

.stripess-container {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -52px;
  top: 50%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  transition: opacity 0.2s ease-out;
  will-change: transform;
}

@media (min-width: 670px) {
  .stripess-container {
    bottom: -90px;
  }
}

@media (min-width: 880px) {
  .stripess-container {
    bottom: -108px;
  }
}

.stripess-container .stripe {
  position: absolute;
}

.stripess-container.initial .s2 {
  height: 54px;
  right: 0;
  left: calc(50% - 25px);
  bottom: -var(--stripe-height);
  background: #87b3f8;
  transition: -webkit-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
@media (min-width: 880px) {
  .stripess-container.initial .s2 {
    left: calc(50% + 142px);
    left: calc(50% + 340px);
  }
}

.stripess-container.initial .s3 {
  height: 54px;
  left: 0;
  width: 72px;
  bottom: 108px;
  bottom: -calc(2 * 54px);
  opacity: 0.09;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: -webkit-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
@media (min-width: 670px) {
  .stripess-container.initial .s3 {
    display: block;
  }
}
@media (min-width: 880px) {
  .stripess-container.initial .s3 {
    background: rgba(0, 0, 0, 0.9);
    right: calc(50% + 410px);
    width: auto;
  }
}
.stripess-container.initial .s4 {
  height: 52px;
  left: calc(50% - 166px);
  right: calc(50% - 46px);
  bottom: -calc(108px - 2);
  bottom: -calc(2 * 54px - 2);
  opacity: 0.09;
  background: #fff;
  mix-blend-mode: soft-light;
  display: none;
}
@media (min-width: 670px) {
  .stripess-container.initial .s4 {
    display: block;
  }
}
@media (min-width: 880px) {
  .stripess-container.initial .s4 {
    left: calc(50% + 30px);
    right: calc(50% - 245px);
  }
}

.stripess-container.initial .s5 {
  height: 54px;
  width: 130px;
  right: 0;
  bottom: 108px;
  bottom: -calc(2 * 54px);
  opacity: 0.09;
  background: #fff;
}

@media (min-width: 670px) {
  .stripess-container.initial .s5 {
    width: 100px;
  }
}

@media (min-width: 880px) {
  .stripess-container.initial .s5 {
    left: calc(50% + 479px);
    width: auto;
  }
}

.stripess-container.initial .s7 {
  height: 54px;
  left: 0;
  right: calc(50% + 225px);
  bottom: -var(--stripe-height);
  background: #e2e9ff;
  transition: -webkit-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
  display: none;
}

@media (min-width: 670px) {
  .stripess-container.initial .s7 {
    display: block;
  }
}

@media (min-width: 880px) {
  .stripess-container.initial .s7 {
    right: calc(50% + 266px);
  }
}
.stripess-container.initial .gradient {
  height: 5000px;
  bottom: 0;
  background: linear-gradient(270deg, #f6f9fc, #fff);
  left: 0;
  right: 0;
}
input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
  border-image: initial;
}
.headline-text {
  font-size: 40px;
  line-height: 50px;
  color: #32325d;
  font-weight: 400;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

@media (min-width: 420px) {
  .headline-text {
    max-width: none;
  }
}

section.form-section {
  position: relative;
  padding-bottom: 50px;
  margin-top: -10px;
}
@media (min-width: 670px) {
  section.form-section {
    margin-top: -20px;
  }
}

@media (min-width: 880px) {
  section.form-section {
    padding-bottom: 30px;
    margin-top: 0;
  }
}

section.form-section {
  max-height: 1200px;
  opacity: 1;
  transition: max-height 0.7s;
  will-change: max-height;
  transition-delay: 0.4s;
}

section.form-section .container-lg {
  position: relative;
}

@media (min-width: 880px) {
  section.form-section .container-lg {
    display: flex;
  }
}

section.form-section .form-column {
  padding-bottom: 45px;
}

@media (min-width: 670px) {
  section.form-section .form-column {
    padding-bottom: 70px;
  }
}

@media (min-width: 880px) {
  section.form-section .container-lg {
    display: flex;
  }
}

@media (min-width: 880px) {
  section.form-section .form-column {
    flex: 6 1;
    padding-bottom: 0;
  }
}

section.form-section .form-container {
  border-radius: 4px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    0 -18px 60px -10px rgba(0, 0, 0, 0.025);
  max-width: 645px;
  flex: 1 1;
  -webkit-flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 0 auto;
}

@media (min-width: 670px) {
  section.form-section .form-container {
    margin: 0;
  }
}
fieldset {
  display: block;
  -webkit-margin-start: 2px;
          margin-inline-start: 2px;
  -webkit-margin-end: 2px;
          margin-inline-end: 2px;
  -webkit-padding-before: 0.35em;
          padding-block-start: 0.35em;
  -webkit-padding-start: 0.75em;
          padding-inline-start: 0.75em;
  -webkit-padding-end: 0.75em;
          padding-inline-end: 0.75em;
  -webkit-padding-after: 0.625em;
          padding-block-end: 0.625em;
  min-inline-size: -webkit-min-content;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
}

section.form-section form {
  padding: 10px 10px 13px 15px;
  padding: 0;
}
@media (min-width: 670px) {
  section.form-section form {
    padding: 10px 18px 20px 24px;
  }
}

section.form-section form fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

section.form-section .form-row {
  padding: 8px 13px 2px 17px;
  border-bottom: 1px solid #e6eef8;
  transition: opacity 0.2s ease-in, height 0.2s ease-out;
}

@media (min-width: 670px) {
  section.form-section .form-row {
    display: flex;
    border-bottom: none;
    padding: 4px 0;
    max-width: 600px;
  }
}

section.form-section .form-row > label {
  color: #6b7c93;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.43px;
  line-height: 26px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  text-transform: uppercase;
  transition: color 0.1s ease-out;
}

@media (min-width: 670px) {
  section.form-section .form-row > label {
    color: #424770;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 26px;
    margin-right: 20px;
    align-self: center;
    flex: 32% 1;
    text-transform: none;
  }
}

section.form-section .form-row input,
section.form-section .form-row select,
section.form-section .form-row textarea {
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  color: #32325d;
  font-family: Camphor, Open Sans, Segoe UI, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  padding: 5px 20px 8px 0;
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
}

@media (min-width: 670px) {
  section.form-section .form-row input,
  section.form-section .form-row select,
  section.form-section .form-row textarea {
    background-color: #f6f9fc;
    padding: 5px 20px 8px 13px;
    flex: 68% 1;
    width: auto;
  }
}

section.form-section .form-row input {
  align-self: center;
  transition: all 0.8s ease-in;
}

section.form-section .form-row input:focus {
  border-bottom: 1px solid #1fdd95;
}
.secondary-column {
  margin-left: auto;
  margin-right: auto;
  max-width: 645px;
}
@media (min-width: 880px) {
  .secondary-column {
    flex: 4 1;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.why-eulerity {
  display: flex;
  flex-direction: column;
}

@media (min-width: 880px) {
  .why-eulerity {
    padding-top: 200px;
  }
}

@media (min-width: 670px) {
  .secondary-column > :first-child {
    padding-left: 24px;
    padding-right: 18px;
  }
}
@media (min-width: 880px) {
  .secondary-column > :first-child {
    padding-left: 50px;
    padding-right: 0;
  }
}

.why-eulerity .intro-text {
  color: #f9fbfd;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
@media (min-width: 880px) {
  .why-eulerity .intro-text {
    max-width: 335px;
  }
}

.why-eulerity .bullet-points {
  margin-top: 20px;
  padding-left: 38px;
}
.why-eulerity .bullet-points li {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 20px;
  position: relative;
  padding-right: 10px;
}

@media (min-width: 880px) {
  .why-eulerity .bullet-points li {
    max-width: 300px;
  }
}

section.ctas {
  position: relative;
  padding-top: 45px;
  padding-bottom: 55px;
  background: rgba(103, 114, 229, 0.5);
  padding-right: 10px;
}
@media (min-width: 880px) {
  section.ctas {
    background: transparent;
    padding-bottom: 90px;
    padding-right: 0;
  }
}

section.ctas {
  opacity: 1;
  max-height: 500px;
  transition: max-height 0.7s;
  will-change: max-height;
  transition-delay: 0.5s;
}
@media (min-width: 670px) {
  section.ctas .container-lg {
    display: flex;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 880px) {
  section.ctas .container-lg {
    padding: 0 20px;
    justify-content: normal;
  }
}

section.ctas .cta {
  flex: 1 1;
}

@media (min-width: 670px) {
  section.ctas .cta {
    max-width: none;
  }
}
@media (min-width: 880px) {
  section.ctas .cta {
    display: flex;
    max-width: none;
  }
}
section.ctas .cta:first-child {
  padding-bottom: 35px;
}

section.ctas .cta:first-child {
  padding-bottom: 0;
}

section.ctas .cta .icon-column {
  min-width: 45px;
  padding-bottom: 15px;
}

@media (min-width: 880px) {
  section.ctas .cta .icon-column {
    padding-bottom: 0;
  }
}

section.ctas .cta .copy-column {
  flex-grow: 2;
  padding-right: 20px;
}

@media (min-width: 880px) {
  section.ctas .cta .copy-column {
    max-width: 385px;
    padding-left: 20px;
  }
}

.cta .cta-headline {
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;
}
.cta .cta-headline i {
  font-size: 20px;
  margin-right: 5px;
}

.linkcontact {
  color: #fff79c;
}

/******tech*******/
section.tech {
  position: relative;
}
section.tech::before {
  height: 100%;
  margin-top: -60px;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

section.tech::after,
section.tech::before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #f6f9fc;
  z-index: -2;
}

section.tech .container-lg {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 880px) {
  section.tech .container-lg {
    position: relative;
    padding-top: 160px;
    padding-bottom: 120px;
  }
}
section.tech .medium-columns {
  position: relative;
}

section.tech .animation {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  flex: 0 0 auto;
  width: 800px;
  height: 800px;
  top: 0;
  margin: -210px -60px 0 -300px;
  -webkit-mask-image: radial-gradient(
    circle closest-side,
    #000 75%,
    transparent 100%
  );
  mask-image: radial-gradient(circle closest-side, #000 75%, transparent 100%);
  z-index: -1;
}
@media (min-width: 880px) {
  section.tech .animation {
    display: block;
  }
}

@media (min-width: 1040px) {
  section.tech .animation {
    margin-left: -220px;
  }
}
section.tech .animation .shadow {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
  background-image: url("https://b.stripecdn.com/site-srv/assets/img/v3/radar_v2/overview/sphere1-2x-01e4b5a72d55a276ac2f5df450877a0b1879a12f.png");
  background-size: cover;
  opacity: 0;
  will-change: opacity;
  transition: opacity 1s ease-out;
}

/* section.tech .animation canvas {
  position: relative;
} */

@media (min-width: 880px) {
  section.tech .copy {
    margin-left: -40px;
  }
}

@media (min-width: 1040px) {
  section.tech .copy {
    margin-left: auto;
  }
}

@media (min-width: 880px) {
  section.tech img {
    display: none;
  }
}

section.tech.section-1 img,
section.tech.section-2 img {
  -webkit-filter: drop-shadow(0 20px 30px #aab7c4);
  filter: drop-shadow(0 20px 30px #aab7c4);
}

section.tech .copy .commonIntroText {
  margin-top: 24px;
}

section.tech .copy .feature-list {
  margin-top: 32px;
}

@media (min-width: 880px) {
  section.tech .copy .feature-list {
    margin-left: 28px;
  }
}
.feature-block {
  flex: 1 1;
  padding: 20px;
  position: relative;
}

@media (min-width: 880px) {
  .md-icon-left {
    padding-left: 50px;
  }
}

.small-row .feature-block {
  flex-basis: auto;
}

.small-icon-top .icon {
  margin: 10px 0 20px -3px;
}

@media (min-width: 880px) {
  .md-icon-left .icon {
    position: absolute;
    left: -24px;
    top: 7px;
  }
}

@media (min-width: 670px) {
  .feature-block .icon {
    width: 300px;
    height: 300px;
  }
}

.feature-block h3,
.feature-block p {
  margin-bottom: 10px;
}

.feature-block h3:last-child,
.feature-block p:last-child {
  margin-bottom: 0;
}

section.tech .copy > * + * {
  margin-top: 40px;
}
@media (min-width: 880px) {
  section.technology .copy > * + * {
    margin-top: 180px;
  }
}

section.techn:after,
section.tech:before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #f6f9fc;
  z-index: -2;
}

section.tech:after {
  height: 400px;
  bottom: 0;
}

.titanMachine {
}

@media (min-width: 670px) {
  .titanMachine {
    width: 300px;
    height: 300px;
  }
}

.cardcontent {
  position: relative;
  z-index: 10;
}

.testimonialsCard {
  --accentColor: #fff;
  --knockoutColor: #17457a;
  --logoContainerHeight: 180px;
  --gradientOffset: calc(20px + var(--logoContainerHeight));
  --gradientStart: transparent calc(-1 * var(--gradientOffset));
  --gradientEnd: var(--backgroundColor) var(--gradientOffset);
  --gradient: linear-gradient(180deg, var(--gradientStart), var(--gradientEnd));
  --logoVerticalOffset: 6px;
  --logoHeight: 40px;
  --buttonHoverColor: var(--buttonColor);
  --buttonHoverOpacity: 0.6;
  --backgroundPosition: top center;
  --backgroundSize: 100% auto;
  --cardBackground: var(--gradient),
    var(--backgroundPosition) / var(--backgroundSize) no-repeat
      var(--backgroundColor) var(--backgroundImage);
}

.testimonialsCard .Card-1,
.testimonialsCard .Card-2 {
  position: relative;
  height: 100%;
}

.testimonialsCard .Card-1 {
  --backgroundColor: #0a2540;
  --backgroundImage: url([object Module]);
  --cardShadow: none;
  --cardShadowMargin: 0;
  --accentColor: #fff;
  --knockoutColor: #17457a;
  --logoContainerHeight: 180px;
  --gradientOffset: calc(20px + var(--logoContainerHeight));
  --gradientStart: transparent calc(-1 * var(--gradientOffset));
  --gradientEnd: var(--backgroundColor) var(--gradientOffset);
  --gradient: linear-gradient(180deg, var(--gradientStart), var(--gradientEnd));
  --logoVerticalOffset: 6px;
  --logoHeight: 40px;
  --buttonHoverColor: var(--buttonColor);
  --buttonHoverOpacity: 0.6;
  --backgroundPosition: top center;
  --backgroundSize: 100% auto;
  --cardBackground: var(--gradient),
    var(--backgroundPosition) / var(--backgroundSize) no-repeat
      var(--backgroundColor) var(--backgroundImage);
  position: relative;
  min-width: 100px;
  min-height: 72px;
  border-radius: var(--cardBorderRadius);
  background: var(--cardBackground);
  box-shadow: var(--cardShadow);
  overflow: hidden;
  margin: 0 20px;
}

.testimonialsCard .Card-2 {
  --backgroundColor: #003f7f;
  --backgroundImage: url([object Module]);
  --cardShadow: none;
  --cardShadowMargin: 0;
  --accentColor: #fff;
  --knockoutColor: #0073e6;
  --logoContainerHeight: 180px;
  --gradientOffset: calc(20px + var(--logoContainerHeight));
  --gradientStart: transparent calc(-1 * var(--gradientOffset));
  --gradientEnd: var(--backgroundColor) var(--gradientOffset);
  --gradient: linear-gradient(180deg, var(--gradientStart), var(--gradientEnd));
  --logoVerticalOffset: 6px;
  --logoHeight: 40px;
  --buttonHoverColor: var(--buttonColor);
  --buttonHoverOpacity: 0.6;
  --backgroundPosition: top center;
  --backgroundSize: 100% auto;
  --cardBackground: var(--gradient),
    var(--backgroundPosition) / var(--backgroundSize) no-repeat
      var(--backgroundColor) var(--backgroundImage);
  --cardShadow: none;
  --cardShadowMargin: 0;
  position: relative;
  min-width: 100px;
  min-height: 72px;
  border-radius: var(--cardBorderRadius);
  background: var(--cardBackground);
  opacity: 0.8;
  box-shadow: var(--cardShadow);
  overflow: hidden;
  margin: 0 20px;
}

.testimonialsCard p {
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans";
  line-height: 1.55;
}
.testimonialsCard h1 {
  font-size: 18px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 1.75;
  text-align: center;
  margin-bottom: 10px;
}

.testimonialsContent {
  display: flex;
  flex-direction: column-reverse;
  min-height: 290px;
  color: var(--accentColor);
}

.testimonialsBody {
  padding: 0 32px 48px;
}

.testimoniallogoContainer {
  position: relative;
  height: var(--logoContainerHeight);
}

.UserLogo {
  --userLogoMaxWidth: none;
  position: absolute;
  top: calc(var(--logoVerticalOffset) + 50%);
  left: 50%;
  height: var(--logoHeight);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0;
  box-shadow: none;
}
.ConnectPayoutsHowItWorksSteps__step {
  --titleColor: #fff;
  --textColor: #f3f6f9;
  --accentColor: transparent;
  --shadeOneColor: #009deb;
  --shadeTwoColor: #0073e6;
  --shadeThreeColor: #003f7f;
  --shadeFourColor: #002c59;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow: visible;
}

@media (min-width: 600px) {
  .ConnectPayoutsHowItWorksSteps__step {
    min-height: 188px;
  }
}

.ConnectPayoutsHowItWorksSteps__step:first-of-type {
  z-index: 4;
  --cardBackground: var(--shadeOneColor);
}
.ConnectPayoutsHowItWorksSteps__step:nth-of-type(2) {
  z-index: 3;
  --cardBackground: var(--shadeTwoColor);
}
.ConnectPayoutsHowItWorksSteps__step:nth-of-type(3) {
  z-index: 2;
  background-color: var(--shadeThreeColor);
}
.ConnectPayoutsHowItWorksSteps__step:nth-of-type(4) {
  z-index: 1;
  background-color: var(--shadeFourColor);
}

.Cardsmall {
  --cardShadow: none;
  --cardShadowMargin: 0;
  position: relative;
  min-width: 100px;
  min-height: 72px;
  border-radius: var(--cardBorderRadius);
  background: var(--cardBackground);
  box-shadow: var(--cardShadow);
  overflow: hidden;
}
.ConnectPayoutsHowItWorksSteps .ColumnLayout {
  align-items: stretch;
  grid-gap: 4px;
  gap: 4px;
}

.ConnectPayoutsHowItWorksSteps__step {
  --titleColor: #fff;
  --textColor: #f3f6f9;
  --accentColor: transparent;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow: visible;
}

@media (min-width: 600px) {
  .ConnectPayoutsHowItWorksSteps__step {
    min-height: 188px;
  }
}

.ConnectPayoutsHowItWorksSteps__step h2 {
  padding: 0 32px 0 16px;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: white;
  font-weight: 500;
  font-family: "Open Sans ", sans-serif;
}

.ConnectPayoutsHowItWorksSteps__step p {
  padding: 0 32px 0 16px;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: white;
  font-weight: 300;
  font-family: "Open Sans ", sans-serif;
}

@media (max-width: 1200px) {
  .app {
    overflow-x: hidden;
  }
}



.helm-featureDetail {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.helm-featureDetail img {
  border-radius: 0;
  box-shadow: none;
}
.helm-featureDetail i {
  font-size: 25px;
  color: #1fdd95;
}
@media (min-width: 670px) {
  .helm-featureDetail {
    margin-bottom: 0;
  }
}

.helm-featureDetail__icon {
  margin: 0 16px 20px 0;
  display: flex;
}
.helm-featureDetail__icon {
  margin: 0 16px 20px 0;
  display: flex;
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.helm-color-green {
  background-color: #1fdd95;
}
.helm-featureDetail__title,
.helm-featureDetail__body {
  margin-bottom: 8px;
}

.commonBodyTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;
}

.commonBodyTitle-slate1 {
  color: #32325d;
  font-weight: 700;
}

.commonBodyText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}

.CommonUpperCaseTitle {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-family: 'Open Sans', sans-serif;
}
@media (min-width: 670px) {
  .CommonUpperCaseTitle {
    font-size: 21px;
    line-height: 32px;
  }
}

.commonBodyText {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
}

.globalFooterCard {
  z-index: 499;
  z-index: calc(500 - 1);
  position: relative;
  display: block;
  overflow: hidden;
  padding: 40px 40px 40px 120px;
  margin: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition-property: color, background-color, box-shadow, -webkit-transform;
  transition-property: color, background-color, box-shadow, transform;
  transition-property: color, background-color, box-shadow, transform, -webkit-transform;
  transition-duration: 0.3s;
}

.globalFooterCard:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.globalFooterCard h2 {
  margin: 0 0 5px;
  white-space: normal;
}

.globalFooterCard .helm-logo {
  position: absolute;
  width: 130px;
  height: 130px;
  left: -35px;
  top: calc(50% - 65px);
}
.globalFooterCard:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #aab7c4;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
}
@media (min-width: 670px) {
  .globalFooterCard {
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 100px;
  }
}

@media (min-width: 880px) {
  .globalFooterCard {
    padding-left: 120px;
  }
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.helm-color-green {
  background-color: #1fdd95;
}

.commonLink {
  color: #6772e5;
  font-weight: 500;
  transition: color 0.1s ease;
  cursor: pointer;
}

.globalFooterCard img {
  border-radius: 0;
  box-shadow: none;
}

.commonIntroText {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
}
@media (min-width: 670px) {
  .commonIntroText {
    font-size: 24px;
    line-height: 36px;
  }
}

.color-blue {
  color: #32325d;
}

.commonGrid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: -2;
}

@media (min-width: 670px) {
  .commonGrid {
    --stripe-height: 64px;
  }
}

.backgroundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.commonGrid .backgroundContainer,
.commonGrid .stripeContainer {
  justify-content: flex-end;
}

.grid {
  --stripe-height: 48px;
  --content-columns: 12;
  --gutter-columns: 4;
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  --gutter-column-width: 1fr;
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, 64px);
  grid-template-columns:
    [viewport-start] 1fr [left-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [left-gutter-end content-start] repeat(
      var(--content-columns),
      var(--content-column-width)
    )
    [content-end right-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [right-gutter-end] 1fr [viewport-end];
}
@media (min-width: 1040px) {
  .commonGrid .grid {
    /* --gutter-column-width: var(--content-column-width); */
    min-width: calc(
      1040px / var(--content-columns) *
        (var(--gutter-columns) * 2 + var(--content-columns))
    );
  }
}
.commonGrid .backgroundContainer .grid {
  grid-template-columns: 1fr;
  min-width: 0;
}

.commonGrid .grid {
  height: 200%;
  align-content: end;
}

.commonGrid .backgroundContainer .background {
  grid-row: 1/-1;
  grid-column: 1/-1;
  z-index: -1;
}

.backgroundColor {
  background-color: #f6f9fc;
  z-index: -1;
}

.commonGrid .stripeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.commonGrid .stripeContainer {
  overflow: hidden;
}

.DeepNav {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: calc(100 - 1);
  padding: 12px 0;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

@media (min-width: 670px) {
  .DeepNav {
    top: 150px;
  }
}
.DeepNav img {
  border-radius: 0;
  box-shadow: none;
}

.DeepNav .container-lg {
  position: relative;
  display: -webkit-box;
  -moz-box-pack: justify;
  display: flex;
  justify-content: space-between;
}

.DeepNav a.deepHome {
  display: flex;
  align-items: center;
}

.DeepNav .deepHome span {
  margin-left: 12px;
}

.helm-logo {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.helm-medium {
  height: 48px;
  width: 48px;
}

.colorize {
  background: rgb(37, 109, 254);
  text-align: center;
}

.boxMenu {
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
  position: absolute;
  left: -50px;
  transition: all 0.7s ease-in-out;
  border: 1px solid #b8b7ad;
}

.show {
  display: none;
  transition: display 0.7 ease-in;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-group {
  outline: none;
  line-height: 30px;
  max-width: 250px;
  margin: auto;
}

.menu-group ul, .menu-group li {
  list-style-position: inside;
  margin-left: 0;
  padding-left: 0;
  text-align: center;
 }

.menu-group img {
  box-shadow: none;
  width: 100%;
  max-width: 200px;
  height: initial;
  display: block;
  margin: auto;
}
.menu-group h2 {
  font-size: 16px;
  font-weight: 425;
  color: #1FDD95;
  text-transform: uppercase;
  text-align: center;
}

.menu-group li a {
  font-size: 16px;
  color: #000;
  transition: color 0.3s ease-in-out;
}

.menu-group li a:visited {
  color: #87a2ff, 100%;
}
.menu-group li a:hover {
  color: #1FDD95;
}

.sbtn {
  display: flex;
  background: #256DFE;
  color: #fff;
  padding: 0.5rem 1.2rem;
  justify-content: center;
  border-radius: 100px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.sbtn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  background: #1FDD95;
}
.intro-visual {
  --logoSize: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 200px;
  padding-bottom: 100%;
  margin-bottom: -190px;
  transition: -webkit-filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

@media (min-width: 670px) {
  .intro-visual--measured {
    -webkit-transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) scale(0.8) !important;
            transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) scale(0.8) !important;
    -webkit-transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) !important;
            transform: translate(-50%, -50%) translate(40px, 40px) perspective(2910px)
      rotateY(-30deg) rotateX(9deg) rotate(5deg) !important;
  }
}

.intro-visual-container {
  position: absolute;
  width: 30%;
  height: 30%;
  top: 50%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 15rem;
}

.intro-visual .layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 100%;
  will-change: transform, opacity;
  box-shadow: 0 2px 5px 0 rgba(26, 26, 67, 0.05),
    13px 20px 125px -25px rgba(50, 50, 93, 0.5),
    16px 20px 75px -37.5px rgba(0, 0, 0, 0.6);
}

.intro-visual .layer--stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: initial;
          filter: initial;
}

.intro-visual .logo-rotator {
  position: absolute;
  left: calc(50% - var(--logoSize) / 2);
  top: calc(50% - var(--logoSize) / 2);
  width: var(--logoSize);
  height: var(--logoSize);
  border-radius: 100%;
}
.intro-visual .layer--stripe svg {
  width: 100%;
  height: 100%;
}

.intro-visual .logo,
.intro-visual .logo-scaler {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  will-change: transform;
}

.intro-visual .logo {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  border-radius: 500px;
}

.HeaderVisualLogo {
  background-size: 310px 310px;
  background-image: url(/static/media/favicon.285a80ed.svg);
}

.HeaderVisualLogo-img0 {
  background-position: 0 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img1 {
  background-position: -52px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img2 {
  background-position: 0 -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img3 {
  background-position: -52px -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img4 {
  background-position: -104px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img5 {
  background-position: -104px -52px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img6 {
  background-position: 0 -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img7 {
  background-position: -52px -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img8 {
  background-position: -104px -104px;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img9 {
  background-position: -156px 0;
  width: 50px;
  height: 50px;
}

.HeaderVisualLogo-img10 {
  background-position: -156px -52px;
  width: 50px;
  height: 50px;
}

/*
.yellow {
  background: yellow;
  width: 30px;
  height: 30px;
  opacity: 1;
}
.green {
  background: green;
  width: 30px;
  height: 30px;
  opacity: 1;
}
.blue {
  background: blue;
  width: 30px;
  height: 30px;
  opacity: 1;
} */

.timeline {
  position: relative;
  max-width: 760px;
  margin: 500px 50px 50px 50px;
  padding: 2rem 0;
  font-family: 'Open Sans', sans-serif;
}

@media (min-width: 670px) {
  .timeline {
    margin: 0 auto;
  }
}

.timeline__header h2 {
  font-size: 34px;
  font-weight: 425;
}

.timeline__header P {
  font-size: 16px;
  font-weight: 425;
}

/* Timeline Header */
.timeline__header {
  text-align: center;
  padding-bottom: 2rem;
}

/* Timeline Content */
.timeline__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: flex-start;
  }
}

/* Timeline center line */
.timeline__content:after {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  content: '';
  width: 4px;
  height: 100%;
  background: #0a2540;
  z-index: -1;
  @media only screen and (max-width: 600px) {
    left: 3rem;
    -webkit-transform: unset;
            transform: unset;
  }
}

/* Timeline Edge Steps */
.timeline__content-edge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 65px;
  width: 65px;
  overflow: hidden;
}
.timeline__content-edge--first {
  background: #0a2540;
  color: white;
  margin-bottom: 1.5rem;
}
.timeline__content-edge--last {
  background: white;
  border: solid 4px #0a2540;
  color: #0a2540;
}
.timeline__content-edge__title {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

/* Timeline Steps */
.timeline__content-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 760px;
}

/* Timeline Single Step */
.timeline__content-step {
  position: relative;
  width: 50%;
  padding-right: 2rem;
  text-align: right;
  @media only screen and (max-width: 600px) {
    width: auto;
    padding: 0 0 0 4rem;
    text-align: left;
  }
}
@media only screen and (min-width: 600px) {
  .timeline__content-step:nth-child(even) {
    align-self: flex-end;
    padding-right: unset;
    padding-left: 2rem;
    text-align: left;
  }
}

/* Timeline Single Step - Title */
.timeline__content-step__title {
  width: 100%;
  padding-bottom: 0.5rem;
  position: relative;
  color: #0a2540;
  font-size: 20px;
}

/* Timeline Single Step - Bottom Line */
.timeline__content-step__title::after {
  display: block;
  content: '';
  width: calc(100% + 2rem);
  height: 3px;
  background: #0a2540;
  position: absolute;
  bottom: -2px;
  right: -2rem;
  @media only screen and (max-width: 600px) {
    right: unset;
    left: -2rem;
  }
}

.timeline__content-step:nth-child(even) .timeline__content-step__title:after {
  right: unset;
  left: -2rem;
}

/* Timeline Single Step - Circle indicator */
.timeline__content-step__title:before {
  display: block;
  content: '';
  width: 2rem;
  height: 2rem;
  background: #0a2540;
  border-radius: 100%;
  position: absolute;
  bottom: -1rem;
  right: -48px;
  z-index: 10;
  @media only screen and (max-width: 600px) {
    right: unset;
    left: -48px;
  }
}
.timeline__content-step:nth-child(even) .timeline__content-step__title:before {
  right: unset;
  left: -48px;
}

/* Timeline Single Step - Description */
.timeline__content-step__description,
.timeline__content-step p {
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
}

.page-wrapper {
  font-family: 'Lato', sans-serif;
  min-height: 30vh;
  background-color: #f5f7fa;
  margin: 20 px auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  padding-left: calc(5% - 16px);
  padding-right: calc(5% - 16px);
  /* padding: 0 5%; */
}

.profile-box {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 3px;
  margin: 16px 16px;
  padding: 35px 32px;
  width: 200px;
  text-align: center;
  transition: 300ms;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.profile-box:hover {
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  box-shadow: 0 0 20px 9px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

img {
  width: 100px;
  height: 100px;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  margin-bottom: 20px;
}

h3 {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
}

h4 {
  font-weight: 300;
  font-size: 15px;
  margin-top: 8px;
  color: #8c9fc3;
  cursor: pointer;
}

.page-wrapper i {
  margin-top: 20px;
  font-size: 30px;
  color: #507bfc;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  color: #2a323a;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  font-family: "Open Sans", sans-serif;
}

td,
th {
  text-align: left;
  padding: 20px 15px;
}

th {
  text-align: center;
  font-size: 1.2em;
}

table i {
  color: #1ad584;
  background-color: white;
  border-radius: 100%;
  margin-right: 5px;
}

table i.red {
  color: red;
}

thead tr {
  background-color: #0a2540;
  color: #fff;
}
tbody tr {
  border-bottom: 1px solid #ccc;
}
tbody tr:nth-child(even) {
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
tbody tr:nth-child(odd) {
  background-color: #eee;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #0a2540;
}

td:first-child {
  font-weight: bold;
}

.error {
  border: 1px solid red;
}

.bg-bleu {
  margin: 30px auto;
  display: flex;
  justify-items: flex-end;
  align-items: flex-end;
  background-color: #256dfe;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
}

.business, .reference {
  color: #424770;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}

.radio {
  font-size: 16px;
  font-weight: 500;
  color: #424770;
  margin: 20px 0;
}

.radio input[type='radio'] {
  margin-right: 10px;
  cursor: pointer;
}

input[type="radio"]{
  -webkit-appearance: radio;
}

input[type="submit"]{
  z-index: 1 !important;
  font-size: 16px;
}

#reference {
  margin-top: 20px;
  height: 30px;
  font-size: 14px;
}

@media only screen 
  and (max-device-width: 678px) {
    .radio_container {
      padding: 0px 18px;
    }

    #reference {
      margin-left: 3px;
    }
}

.errorContainer {
  padding: 20px;
  background: #f6645a;
  color: white;
  font-size: 15px;
}

.errorContainer i {
  color: #f6645a;
}

.successContainer {
  padding: 20px;
  background-color: #6bbd6f;
}

.success {
  color: white;
  font-size: 16px;
}

.successContainer i {
  display: inline;
  margin-left: 20px;
}

.inlineError {
  color: #f6645a;
}

.globalFooter {
  z-index: 1;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f9fc;
  position: relative;
}

.globalFooterNav {
  padding: 65px 0 55px;
  color: #8898aa;
  line-height: 30px;
  font-size: 15px;
  white-space: nowrap;
}

.globalFooterNav .container-lg {
  display: flex;
  flex-wrap: wrap;
}
.globalFooterNav .metaNav {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  flex-basis: 100%;
  margin-bottom: 20px;
}

.globalFooterNav .metaNav .space {
  flex-grow: 2;
}
.globalFooterNav .metaNav .copyright {
  margin-right: 0;
  color: #aab7c4;
}

@media (min-width: 880px) {
  .globalFooterNav .container-lg {
    flex-wrap: nowrap;
  }
}

@media (min-width: 880px) {
  .globalFooterNav .metaNav {
    flex-basis: auto;
    flex-direction: column;
    margin: 0 0 20px;
  }
}

.globalFooterNav .siteNav {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: space-between;
}

@media (min-width: 670px) {
  .globalFooterNav .siteNav {
    flex-wrap: nowrap;
  }
}
@media (min-width: 880px) {
  .globalFooterNav .siteNav {
    flex-grow: 0;
  }
}

.globalFooterNav .siteNav .column {
  flex-basis: 50%;
  margin-bottom: 20px;
}

@media (min-width: 670px) {
  .globalFooterNav .siteNav .column {
    flex-basis: auto !important;
    margin-right: 40px;
  }
}

@media (min-width: 880px) {
  .globalFooterNav .siteNav .column {
    margin-right: 30px;
  }
}

@media (min-width: 1040px) {
  .globalFooterNav .siteNav .column {
    margin-right: 40px;
  }
}

.globalFooterNav .siteNav .splitColumn {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

hr {
  width: 100%;
  margin: 20px auto;
  border-bottom: 1px dotted rgba(207, 215, 223, 0.25);
}

.globalFooterNav a {
  text-decoration: none;
  margin: auto;
  color: inherit;
  transition: color 0.1s;
}

.thank {
  font-family: 'Open Sans', sans-serif;
  padding: 40px;
}

.thank .container-lg {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.thank .container-lg .title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.8;
}
.thank .container-lg .image1 {
  width: 100%;
  height: 500px;
}

@media (min-width: 670px) {
  .thank .container-lg .image1 {
    width: 50%;
  }
}

.thank-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
}
.thank-content h1 {
  text-align: center;
}
.thankBoxContent {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 670px) {
  .thankBoxContent {
    width: 50%;
  }
}

.container-lg {
  margin: 100px auto;
}
.tour {
  margin: 200px auto;
  display: flex;
  justify-items: center;
  justify-content: center;
  width: 80%;
  flex-direction: column-reverse;
}

.btns {
  display: flex;
  width: 40%;
  height: 100px;
  justify-content: space-between;
}

.formTour {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px auto;
  width: 100%;
}

.hide {
  display: none;
}

.show {
  display: flex;
}
.formTour input {
  width: 100%;
  padding: 10px;
  margin: 20px auto;
  transition: width 0.5s ease;
}

.formTour label {
  font-size: 16px;
  font-weight: 400;
}

.errorMessage {
  padding: 15px;
  color: white;
  background: red;
}

@media (max-width: 880px) {
  .formTour input[type="text"] {
    width: 100%;
  }
}

.formTour input[type="submit"] {
  width: 40%;
  margin: 0 auto;
  text-align: center;
  background-color: #256dfe;
  color: white;
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
}

@media (max-width: 880px) {
  .tour {
    width: 100%;
  }
  .btns {
    width: 100%;
  }
}

@media (min-width: 881px) {
  .btns {
    width: 35%;
  }
  .formTour {
    width: 40%;
  }
  .formTour label {
    font-size: 20px;
  }
}

.emailFailure {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.emailFailure .failureblog {
  width: 90%;
  margin: 0 auto;
}

.sky {
  position: relative;
}
.sky .container-lg {
  margin-top: 30px;
  width: 90%;
  text-align: center;
}
.sky .container-lg h1 {
  margin-bottom: 20px;
}

.sky .container-lg img {
  border-radius: 0;
  width: 90%;
  height: 90%;
}

.covid {
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

.covid .titleContent {
  width: 100%;
  margin: 50px auto;
  text-align: center;
  padding: 30px;
}
@media (min-width: 375px) {
  .covid .titleContent {
    width: 70%;
    margin: 50px auto;
  }
}

.covid .titleContent .titleContent_title {
  margin-bottom: 30px;
  font-size: 18px;
}
@media (min-width: 375px) {
  .covid .titleContent .titleContent_title {
    font-size: 30px;
  }
}

.covid .titleContent .titleContent_text {
  font-size: 18px;
}
@media (min-width: 375px) {
  .covid .titleContent .titleContent_text {
    font-size: 20px;
  }
}

.gradual {
  position: relative;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 670px) {
  .gradual {
    padding: 50px 30px;
    justify-content: space-between;
    flex-direction: row;
  }
}

.gradual_img {
  flex: 40% 1;
}

.gradual_img img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.gradual_textContent {
  flex: 60% 1;
}

.gradual ul li {
  list-style: decimal;
  color: black;
}

@media (min-width: 375px) {
  .gradual ul li {
    font-size: 18px;
  }
}
.gradual ul li:not(:first-child) {
  margin-top: 20px;
}

.gradual_secondaryList {
  margin: 20px 35px;
}

.hr {
  width: 50%;
  height: 20%;
  border: 1px solid #000000;
}

.scale {
  display: flex;
  flex-direction: column;
}

@media (min-width: 675px) {
  .scale {
    flex-direction: row;
    padding: 30px 20px;
  }
}

.scaleContent {
  flex: 60% 1;
  padding: 10px;
}

.scaleContent h2 {
  margin-bottom: 20px;
}

@media (min-width: 675px) {
  .scaleContent p {
    font-size: 18px;
  }
}

.scale_list li {
  list-style: decimal;
  color: #000000;
}

.scaleImg {
  flex: 40% 1;
}

.scaleImg img {
  width: 80%;
  height: 80%;
  box-shadow: none;
}

.scale ul li:not(:first-child) {
  margin-top: 20px;
}

.schedule {
  position: relative;
  width: 70%;
  margin: 30px auto;
  padding: 30px 30px 50px 30px;
  text-align: center;
}

.schedule h2 {
  margin-bottom: 20px;
}

.schedule p {
  margin-bottom: 20px;
}

@media (min-width: 675px) {
  .schedule p {
    font-size: 18px;
    text-align: center;
  }
}

.faq {
  position: relative;

  width: 60%;
  margin: 0 auto;
}

.faq h1 {
  text-align: center;
  margin-bottom: 20px;
}

.faq .faqContent {
  padding: 20px;
  margin: 20px 5px;
}

.faq .faqContent .faqQuestion {
  font-weight: bold;
  margin-bottom: 10px;
}

.plan {
  width: 50%;
  margin: 0 auto;
}

.plan li {
  list-style: disc;
}
.plan li a {
  color: black;
  font-weight: bold;
}

.plan li a:hover {
  color: #256dfe;
}
.plan li:not(:first-child) {
  margin-top: 20px;
}

.dynamic {
  position: relative;
  padding: 50px 20px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 30px;
}

.dynamic img {
  border-radius: 0;
  box-shadow: none;
  width: 40%;
  height: 40%;
}
.dynamicBloc {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dynamicBloc h1 {
  margin-bottom: 30px;
}

.dynamicBloc_content {
  display: flex;
  height: 100px;
  width: 50%;
  margin-left: 100px;
  flex-direction: column;
  justify-content: space-between;
}

.dynamicBloc_text {
  font-size: 18px;
  margin-bottom: 30px;
}

.successful {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  margin: 40px auto;
  font-family: 'Open Sans', sans-serif;
}

@media (min-width: 670px) {
  .successful {
    margin-top: 0;
  }
}
.success_title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media (min-width: 670px) {
  .success_title {
    font-size: 50px;
  }
}

.success_icon {
  margin-bottom: 30px;
  font-size: 30px;
}
@media (min-width: 670px) {
  .success_icon {
    font-size: 45px;
    color: #1fdd95;
  }
}

.success_text {
  font-size: 25px;
  margin-bottom: 30px;
}

.success_subtext {
  font-size: 25px;
}

@media (min-width: 670px) {
  .success_text {
    font-size: 35px;
    width: 70%;
    margin: 30px auto;
  }
}
.success_button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: 30px auto;

  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #1fdd95;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
}

