table {
  border-collapse: collapse;
  width: 100%;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  color: #2a323a;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  font-family: "Open Sans", sans-serif;
}

td,
th {
  text-align: left;
  padding: 20px 15px;
}

th {
  text-align: center;
  font-size: 1.2em;
}

table i {
  color: #1ad584;
  background-color: white;
  border-radius: 100%;
  margin-right: 5px;
}

table i.red {
  color: red;
}

thead tr {
  background-color: #0a2540;
  color: #fff;
}
tbody tr {
  border-bottom: 1px solid #ccc;
}
tbody tr:nth-child(even) {
  background-color: #fff;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
tbody tr:nth-child(odd) {
  background-color: #eee;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #0a2540;
}

td:first-child {
  font-weight: bold;
}
