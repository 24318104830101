.page-wrapper {
  font-family: 'Lato', sans-serif;
  min-height: 30vh;
  background-color: #f5f7fa;
  margin: 20 px auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  padding-left: calc(5% - 16px);
  padding-right: calc(5% - 16px);
  /* padding: 0 5%; */
}

.profile-box {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  border-radius: 3px;
  margin: 16px 16px;
  padding: 35px 32px;
  width: 200px;
  text-align: center;
  transition: 300ms;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.profile-box:hover {
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  box-shadow: 0 0 20px 9px rgba(0, 0, 0, 0.03);
  cursor: pointer;
}

img {
  width: 100px;
  height: 100px;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 151, 0.1);
  margin-bottom: 20px;
}

h3 {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
}

h4 {
  font-weight: 300;
  font-size: 15px;
  margin-top: 8px;
  color: #8c9fc3;
  cursor: pointer;
}

.page-wrapper i {
  margin-top: 20px;
  font-size: 30px;
  color: #507bfc;
}
