.commonGrid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: -2;
}

@media (min-width: 670px) {
  .commonGrid {
    --stripe-height: 64px;
  }
}

.backgroundContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.commonGrid .backgroundContainer,
.commonGrid .stripeContainer {
  justify-content: flex-end;
}

.grid {
  --stripe-height: 48px;
  --content-columns: 12;
  --gutter-columns: 4;
  --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
  --gutter-column-width: 1fr;
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fill, 64px);
  grid-template-columns:
    [viewport-start] 1fr [left-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [left-gutter-end content-start] repeat(
      var(--content-columns),
      var(--content-column-width)
    )
    [content-end right-gutter-start] repeat(
      var(--gutter-columns),
      var(--gutter-column-width)
    )
    [right-gutter-end] 1fr [viewport-end];
}
@media (min-width: 1040px) {
  .commonGrid .grid {
    /* --gutter-column-width: var(--content-column-width); */
    min-width: calc(
      1040px / var(--content-columns) *
        (var(--gutter-columns) * 2 + var(--content-columns))
    );
  }
}
.commonGrid .backgroundContainer .grid {
  grid-template-columns: 1fr;
  min-width: 0;
}

.commonGrid .grid {
  height: 200%;
  align-content: end;
}

.commonGrid .backgroundContainer .background {
  grid-row: 1/-1;
  grid-column: 1/-1;
  z-index: -1;
}

.backgroundColor {
  background-color: #f6f9fc;
  z-index: -1;
}

.commonGrid .stripeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
}

.commonGrid .stripeContainer {
  overflow: hidden;
}
