.emailFailure {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.emailFailure .failureblog {
  width: 90%;
  margin: 0 auto;
}
