.successful {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  margin: 40px auto;
  font-family: 'Open Sans', sans-serif;
}

@media (min-width: 670px) {
  .successful {
    margin-top: 0;
  }
}
.success_title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}

@media (min-width: 670px) {
  .success_title {
    font-size: 50px;
  }
}

.success_icon {
  margin-bottom: 30px;
  font-size: 30px;
}
@media (min-width: 670px) {
  .success_icon {
    font-size: 45px;
    color: #1fdd95;
  }
}

.success_text {
  font-size: 25px;
  margin-bottom: 30px;
}

.success_subtext {
  font-size: 25px;
}

@media (min-width: 670px) {
  .success_text {
    font-size: 35px;
    width: 70%;
    margin: 30px auto;
  }
}
.success_button {
  width: fit-content;
  cursor: pointer;
  margin: 30px auto;

  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #1fdd95;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
