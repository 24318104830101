.sky {
  position: relative;
}
.sky .container-lg {
  margin-top: 30px;
  width: 90%;
  text-align: center;
}
.sky .container-lg h1 {
  margin-bottom: 20px;
}

.sky .container-lg img {
  border-radius: 0;
  width: 90%;
  height: 90%;
}
