.thank {
  font-family: 'Open Sans', sans-serif;
  padding: 40px;
}

.thank .container-lg {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.thank .container-lg .title {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.8;
}
.thank .container-lg .image1 {
  width: 100%;
  height: 500px;
}

@media (min-width: 670px) {
  .thank .container-lg .image1 {
    width: 50%;
  }
}

.thank-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
}
.thank-content h1 {
  text-align: center;
}
.thankBoxContent {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 670px) {
  .thankBoxContent {
    width: 50%;
  }
}
