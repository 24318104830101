.error {
  border: 1px solid red;
}

.bg-bleu {
  margin: 30px auto;
  display: flex;
  justify-items: flex-end;
  align-items: flex-end;
  background-color: #256dfe;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
}

.business, .reference {
  color: #424770;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}

.radio {
  font-size: 16px;
  font-weight: 500;
  color: #424770;
  margin: 20px 0;
}

.radio input[type='radio'] {
  margin-right: 10px;
  cursor: pointer;
}

input[type="radio"]{
  -webkit-appearance: radio;
}

input[type="submit"]{
  z-index: 1 !important;
  font-size: 16px;
}

#reference {
  margin-top: 20px;
  height: 30px;
  font-size: 14px;
}

@media only screen 
  and (max-device-width: 678px) {
    .radio_container {
      padding: 0px 18px;
    }

    #reference {
      margin-left: 3px;
    }
}
